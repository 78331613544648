import { EventLevels } from "../../Models/enumTypes";
import * as iconOutline from "@heroicons/react/24/outline";
import * as iconSolid from "@heroicons/react/20/solid";
import { useState } from "react";
import { Event } from "../../Models/TethrApi";
import { Fragment } from "react";
import RecomendationModal from "../RecommendationModel";

export class EventColor {
  light!: string;
  dark!: string;
}

export interface EventLevelProps {
  level: EventLevels;
  className?: string;
  isSystemParticipant?: boolean;
}

export function GetEventColor(level: EventLevels): EventColor {
  /**
   * NOTE: any color in here, needs to be added to the tailwind.config.js purge.salfelist
   * with the following prefixs
   *  - bg-
   *  - border-
   *  - text-
   *
   * See: https://v2.tailwindcss.com/docs/optimizing-for-production#writing-purgeable-html
   */

  switch (level) {
    case EventLevels.Info:
      return { light: "blue-50", dark: "blue-700" };
    case EventLevels.Warning:
      return { light: "red-50", dark: "red-700" };
    case EventLevels.Alert:
      return { light: "red-50", dark: "red-700" };
    case EventLevels.Success:
      return { light: "green-50", dark: "green-700" };
    case EventLevels.None:
    default:
      return { light: "gray-200", dark: "gray-800" };
  }
}

export const EventIcon: React.FC<EventLevelProps> = (props) => {
  if (props.isSystemParticipant) {
    switch (props.level) {
      case EventLevels.Warning:
        return <iconSolid.ExclamationCircleIcon className={props.className ?? "h-6 w-6"} />;
      case EventLevels.Alert:
        return <iconSolid.ExclamationTriangleIcon className={props.className ?? "h-6 w-6"} />;
      case EventLevels.Success:
      case EventLevels.Info:
      case EventLevels.None:
      default:
        return <iconSolid.BeakerIcon className={props.className ?? "h-6 w-6"} />;
    }
  }

  switch (props.level) {
    case EventLevels.Success:
      return <iconSolid.CheckCircleIcon className={props.className ?? "h-6 w-6"} />;
    case EventLevels.Warning:
      return <iconSolid.ExclamationCircleIcon className={props.className ?? "h-6 w-6"} />;
    case EventLevels.Alert:
      return <iconSolid.ExclamationTriangleIcon className={props.className ?? "h-6 w-6"} />;
    case EventLevels.Info:
      return <iconSolid.DocumentTextIcon className={props.className ?? "h-6 w-6"} />;
    case EventLevels.None:
    default:
      return <iconSolid.NewspaperIcon className={props.className ?? "h-6 w-6"} />;
  }
};

interface RecommendationProps {
  text: string;
  /** If it's the only button in the button bar, then the right side needs to be rounded too */
  isOnly?: boolean;
}

const btnClassMid =
  "inline-flex items-center px-4 py-2 text-sm font-medium text-white bg-tethr-blue border-t border-b border-tethr-blue-dark hover:bg-tethr-blue-dark focus:z-10 focus:ring-2 focus:ring-gray-500 focus:bg-tethr-blue-dark";
const btnClassFirst =
  "inline-flex items-center px-4 py-2 text-sm font-medium text-white bg-tethr-blue border border-tethr-blue-dark rounded-l-lg hover:bg-tethr-blue-dark focus:z-10 focus:ring-2 focus:ring-gray-500 focus:bg-tethr-blue-dark";
const btnClassLast =
  "inline-flex items-center px-4 py-2 text-sm font-medium text-white bg-tethr-blue border border-tethr-blue-dark rounded-r-md hover:bg-tethr-blue-dark  focus:z-10 focus:ring-2 focus:ring-gray-500 focus:bg-tethr-blue-dark";
const btnClassOnly =
  "inline-flex items-center px-4 py-2 text-sm font-medium text-white bg-tethr-blue border border-tethr-blue-dark rounded-l-lg rounded-r-md hover:bg-tethr-blue-dark  focus:z-10 focus:ring-2 focus:ring-gray-500 focus:bg-tethr-blue-dark";

const RecommendationBtn: React.FC<RecommendationProps> = (props) => {
  const [showModal, setshowModel] = useState(false);
  // Recomendation is always the first button, and there is only ever one.
  // but what makes this diffrent is the model
  return (
    <Fragment>
      {showModal && <RecomendationModal onClose={() => setshowModel(false)} text={props.text} />}
      <button type='button' onClick={() => setshowModel(true)} className={props.isOnly ? btnClassOnly : btnClassFirst}>
        <iconOutline.DocumentMagnifyingGlassIcon className='w-4 h-4 mr-2 fill-current' />
        Recommendation
      </button>
    </Fragment>
  );
};

export interface EventProps {
  /** Show the close button */
  isAlert?: boolean;

  /**
   * Hide the action buttons and show in condenced form.
   * example use would in a transcript line
   */
  inLine?: boolean;

  /**
   * Hide the action buttons.
   */
  disableLinks?: boolean;

  /** Event data */
  data: Event;
}

export const EventBtnGroup: React.FC<EventProps> = (props) => {
  let hasRecommendation = (props.data.recommendation?.length ?? 0) > 0;
  let hasLinks = (props.data.links?.length ?? 0) > 0;
  if (!hasLinks && !hasRecommendation) {
    return <></>;
  }

  return (
    <div className='inline-flex rounded-md shadow-sm pt-3' role='group'>
      {hasRecommendation && <RecommendationBtn text={props.data.recommendation ?? ""} isOnly={!hasLinks} />}
      {hasLinks &&
        props.data.links?.map((link, index) => {
          let btnClass = btnClassMid;
          if (!hasRecommendation && props.data.links!.length === 1) {
            btnClass = btnClassOnly;
          } else if (!hasRecommendation && index === 0) {
            btnClass = btnClassFirst;
          } else if (index === props.data.links!.length - 1) {
            btnClass = btnClassLast;
          }

          return (
            <a key={props.data.id} type='button' target={"_blank"} rel='noreferrer' referrerPolicy={"same-origin"} className={btnClass} href={link.url}>
              <iconOutline.LinkIcon className='w-4 h-4 mr-2 fill-current' />
              {link.title}
            </a>
          );
        })}
    </div>
  );
};
