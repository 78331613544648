import { XMarkIcon } from "@heroicons/react/20/solid";
import ReactMarkdown from "react-markdown";
import "./RecommendationMarkdown.css"

interface RecommendationProps {
  onClose(): void;
  text: string;
}
const RecomendationModal: React.FC<RecommendationProps> = (props) => {
    return (
      <div
        id='recommendationModal'
        tabIndex={-1}
        aria-hidden='true'
        className='fixed top-0 left-0 right-0 z-50 w-fit p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] md:h-full'
      >
        <div className='relative w-full max-w-2xl md:h-auto shadow-lg shadow-gray-700'>
          <div className='relative bg-white h-full rounded-lg shadow dark:bg-gray-700'>
            <div className='flex items-start justify-between p-4 border-b rounded-t dark:border-gray-600'>
              <h3 className='text-xl font-semibold text-gray-900 dark:text-white'>Recommendation</h3>
              <button
                type='button'
                onClick={() => props.onClose()}
                className='ml-auto -mx-1.5 -my-1.5 rounded-lg focus:ring-2 p-1.5 inline-flex h-8 w-8 hover:bg-gray-200 focus:ring-gray-300'
              >
                <span className='sr-only'>Close</span>
                <XMarkIcon className='h-6 w-6' />
              </button>
            </div>
            <div className='p-6 space-y-6 text-base leading-relaxed subpixel-antialiased text-recommendation text-gray-500 dark:text-gray-400'>
              <ReactMarkdown>{props.text}</ReactMarkdown>
            </div>
            <div className='flex items-center p-6 space-x-2 border-t border-gray-200 rounded-b dark:border-gray-600'>
              <button
                onClick={() => props.onClose()}
                type='button'
                className='text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800'
              >
                close
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };
  
  export default RecomendationModal