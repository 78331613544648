import { connect, useSelector } from "react-redux";
import { RootState } from "../store";

const options: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: 'short',
  day: 'numeric',
  timeZoneName: 'short',
  hour: 'numeric',
  minute: '2-digit',
};

function InteractionDetails() {
  const summary = useSelector((state: RootState) => state.interaction.summary);
  const participants = useSelector((state: RootState) => state.interaction.participants);
  const details = useSelector((state: RootState) => state.interaction.details);
  
  const customer = summary.customerParticipantId !== null && participants[summary.customerParticipantId];
  const agent = summary.agentParticipantId !== null && participants[summary.agentParticipantId];

  const utcStart = details?.utcStart && new Date(details.utcStart);

  let duration = '';
  if(details?.utcEnd && utcStart){
    const end = new Date(details.utcEnd);
    duration = ` • ${Math.floor((end.getTime() - utcStart.getTime()) / 6000)/10}mins`;
  }

  let customername = '';
  if(customer){
    if(customer.firstName && !customer.lastName){
      customername = customer.firstName;
    } else if(!customer.firstName && customer.lastName){
      customername = customer.lastName;
    } else if(customer.firstName && customer.lastName){
      customername = `${customer.firstName} ${customer.lastName}`;
    } else if(customer.email) {
      customername = customer.email;
    } else if(customer.phoneNumber){
      customername = customer.phoneNumber;
    }
  }

  let agentName = '';
  if(agent){
    if(agent.firstName && !agent.lastName){
      agentName = agent.firstName;
    } else if(!agent.firstName && agent.lastName){
      agentName = agent.lastName;
    } else if(agent.firstName && agent.lastName){
      agentName = `${agent.firstName} ${agent.lastName}`;
    } else if(agent.email) {
      agentName = agent.email;
    } else if(agent.phoneNumber){
      agentName = agent.phoneNumber;
    }
  }
  return (
    <div className='grid grid-cols-2 text-gray-800 gap-2'>
      <div className='col-span-2 font-medium'>Details</div>
      <div>
        <div className='text-gray-400 text-xs font-normal pb-1'>Agent</div>
        <div className='text-sm font-normal'>{agentName}</div>
      </div>
      <div>
        <div className='text-gray-400 text-xs font-normal pb-1'>Team</div>
        <div className='text-sm font-normal'>{details?.displayName}, {details?.direction}</div>
      </div>
      <div>
        <div className='text-gray-400 text-xs font-normal pb-1'>Customer</div>
        <div className='text-sm font-normal'>{customername}</div>
      </div>
      <div>
        <div className='text-gray-400 text-xs font-normal pb-1'>Date & time</div>
        <div className='text-sm font-normal'>{utcStart?.toLocaleString(undefined, options)}{duration}</div>
      </div>
    </div>
  );
}

export default connect()(InteractionDetails);
