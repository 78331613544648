import { useState } from "react";
import { connect, useSelector } from "react-redux";
import { EventLevels } from "../../Models/enumTypes";
import { RootState } from "../../store";
import AlertItem from "./AlertItem";
import Events from "./EventTimeline";
import Transcript from "./Transcript";
import { TranscriptFilterMode } from "./TranscriptFilterMode";

export interface TranscriptContainerProps {
  /**
   * Hide the action buttons.
   */
  disableLinks?: boolean;
}

export const TranscriptContainer: React.FC<TranscriptContainerProps> = (props) => {
  const events = useSelector((state: RootState) => state.interaction.events);
  const [filterMode, setFilterMode] = useState(TranscriptFilterMode.events);
  const activeTabClass = "inline-block w-full py-2 px-4 bg-white hover:text-gray-700 hover:bg-gray-100 focus:ring-2 focus:ring-blue-300 focus:outline-none";
  const inActiveTabClass = "inline-block w-full py-2 px-4 bg-gray-200 hover:text-gray-700 hover:bg-gray-50 focus:ring-2 focus:ring-blue-300 focus:outline-none";

  // Add up the count events in the timeLineEvents array
  var alertCount = events.filter((tle) => tle.level === EventLevels.Alert || tle.level === EventLevels.Warning).length;
  var eventCount = events.length;
  return (
    <div className='space-y-3'>
      <div className='font-medium'>Timeline</div>
      <ul className='text-sm font-medium text-center text-gray-900 divide-x divide-gray-200 rounded-md flex w-fit border-2 border-gray-200'>
        <li>
          <button
            className={`rounded-l-md ${filterMode === TranscriptFilterMode.all ? activeTabClass : inActiveTabClass}`}
            aria-current='page'
            onClick={() => setFilterMode(TranscriptFilterMode.all)}
          >
            All
          </button>
        </li>
        <li>
          <button
            className={filterMode === TranscriptFilterMode.events ? activeTabClass : inActiveTabClass}
            onClick={() => setFilterMode(TranscriptFilterMode.events)}
          >
            Events ({eventCount})
          </button>
        </li>
        <li>
          <button
            className={filterMode === TranscriptFilterMode.alerts ? activeTabClass : inActiveTabClass}
            onClick={() => setFilterMode(TranscriptFilterMode.alerts)}
          >
            Alerts ({alertCount})
          </button>
        </li>
        <li>
          <button
            className={`rounded-r-md ${filterMode === TranscriptFilterMode.transcriptionOnly ? activeTabClass : inActiveTabClass}`}
            onClick={() => setFilterMode(TranscriptFilterMode.transcriptionOnly)}
          >
            Transcript
          </button>
        </li>
      </ul>
      <div className='pt-3'>
        {filterMode === TranscriptFilterMode.events ? (
          <Events />
        ) : filterMode === TranscriptFilterMode.alerts ? (
          <ol className='space-y-3'>
            {events
              .filter((tle) => tle.level === EventLevels.Alert || tle.level === EventLevels.Warning)
              .map((ev) => {
                return <AlertItem key={ev.id} inLine={false} disableLinks={props.disableLinks} data={ev} />;
              })}
          </ol>
        ) : (
          <Transcript hideEvents={filterMode === TranscriptFilterMode.transcriptionOnly} disableLinks />
        )}
      </div>
    </div>
  );
};

export default connect()(TranscriptContainer);
