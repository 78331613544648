import LiveInteraction from "./TethrApi";

export default class SseClient {

    private _eventSource?: EventSource;
    private _url: string;
    private _onMessage: (event: MessageEvent) => void;
    private _onError: (event: Event) => void;
    private _onOpen: (event: Event) => void;
    private _onInteractionEvent: (event: LiveInteraction) => void;
    
    constructor(
            url: string, 
            onMessage: (event: MessageEvent) => void, 
            onError: (event: Event) => void, 
            onOpen: (event: Event) => void,
             onInteractionEvent: (event: LiveInteraction) => void
    ) {
        this._url = url;
        this._onMessage = onMessage;
        this._onError = onError;
        this._onOpen = onOpen;
        this._onInteractionEvent = onInteractionEvent;
        console.log('Connection SSE New', url);
    }
    
    public start() :SseClient {
        console.log('Connection SSE Start');
        this._eventSource = new EventSource(this._url);
        this._eventSource.onmessage = this._onMessage;
        this._eventSource.onerror = this._onError;
        this._eventSource.onopen = this._onOpen;
        this._eventSource.addEventListener('LiveInteraction', (e) => {
            this._onInteractionEvent(JSON.parse(e.data));
        })

        return this;
    }
    
    public stop() {
        // check if eventSource is defined and close if it is
        if (this._eventSource) {
            console.log('Connection SSE Stop');
            this._eventSource.close();
        }
    }
}