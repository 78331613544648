const Logo = () => ( <svg className='fill-tethr-blue' xmlns='http://www.w3.org/2000/svg' viewBox="0 0 68 26">
<g>
  <path
    d='M8.21,24.312c-0.199,0-0.377-0.217-0.377-0.416V6.229c0-0.132-0.021-0.122-0.153-0.122H2.332
  C2.133,6.107,2,5.898,2,5.698V2.019c0-0.199,0.133-0.331,0.332-0.331H18.12c0.199,0,0.318,0.132,0.318,0.331v3.679
  c0,0.199-0.118,0.408-0.318,0.408h-5.347c-0.133,0-0.168-0.011-0.168,0.122v17.667c0,0.199-0.164,0.416-0.362,0.416H8.21z'
  />
  <path
    d='M15.08,19.718c-0.332-0.994-0.563-2.154-0.563-3.812c0-1.69,0.199-2.32,0.53-3.314
  c0.928-2.884,3.303-4.541,6.718-4.541c3.546,0,5.822,1.69,6.751,4.508c0.331,1.061,0.53,1.695,0.53,4.413
  c0,0.199-0.133,0.336-0.365,0.336h-9.358c-0.133,0-0.199,0.062-0.199,0.194c0.033,0.332,0.1,0.627,0.199,0.893
  c0.431,1.392,1.48,2.12,3.138,2.12c1.591,0,2.574-0.564,3.502-1.426c0.165-0.132,0.332-0.166,0.464,0l2.188,2.055
  c0.165,0.132,0.165,0.332,0.033,0.464c-1.525,1.624-3.701,2.685-6.651,2.685C18.35,24.293,16.008,22.602,15.08,19.718z
   M24.273,14.833c0.132,0,0.199-0.11,0.199-0.242c0-0.497-0.066-0.884-0.165-1.215c-0.365-1.094-1.183-1.767-2.508-1.767
  c-1.326,0-2.144,0.657-2.508,1.751c-0.1,0.332-0.166,0.737-0.166,1.234c0,0.132,0.066,0.239,0.199,0.239H24.273z'
  />
  <path
    d='M35.871,24.312c-3.712,0-5.061-1.743-5.061-5.421v-7.115c0-0.132-0.11-0.189-0.242-0.189h-0.994
  c-0.199,0-0.354-0.143-0.354-0.341V8.317c0-0.199,0.155-0.266,0.354-0.266h0.994c0.132,0,0.242-0.132,0.242-0.264v-4.53
  c0-0.199,0.09-0.331,0.288-0.331h3.944c0.199,0,0.363,0.132,0.363,0.331v4.53c0,0.132,0.035,0.264,0.167,0.264h2.055
  c0.199,0,0.252,0.067,0.252,0.266v2.928c0,0.199-0.053,0.341-0.252,0.341h-2.055c-0.132,0-0.167,0.057-0.167,0.189v6.916
  c0,1.193,0.366,1.556,1.427,1.556h0.795c0.199,0,0.252,0.168,0.252,0.367v3.281c0,0.199-0.053,0.417-0.252,0.417H35.871z'
  />
  <path
    d='M49.039,24.312c-0.199,0-0.378-0.217-0.378-0.416v-9.038c0-1.756-0.801-2.916-2.458-2.916
  c-1.591,0-2.491,1.16-2.491,2.916v9.038c0,0.199-0.142,0.416-0.341,0.416h-3.978c-0.199,0-0.277-0.217-0.277-0.239V3.444V2.019
  c0-0.199,0.078-0.331,0.277-0.331h3.978c0.199,0,0.341,0.132,0.341,0.331v7.799h0.023c0.73-1.06,2.131-1.823,4.186-1.823
  c3.547,0,5.158,2.454,5.158,5.967v9.933c0,0.199-0.218,0.416-0.417,0.416H49.039z'
  />
  <path
    d='M54.603,24.312c-0.2,0-0.334-0.134-0.334-0.334V8.665c0-0.2,0.134-0.334,0.334-0.334h3.829
  c0.2,0,0.334,0.134,0.334,0.334v1.369H58.8c0.768-1.302,2.237-1.893,4.206-1.893c1.068,0,2.137,0.367,2.838,0.968
  c0.167,0.134,0.2,0.267,0.1,0.467l-1.869,3.405c-0.134,0.167-0.267,0.167-0.467,0.067c-0.768-0.501-1.536-0.944-2.337-0.911
  c-1.736,0.067-2.504,1.335-2.504,3.505v8.336c0,0.2-0.134,0.334-0.334,0.334H54.603z'
  />
</g>
</svg>)

 export default Logo;