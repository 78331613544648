
import { ScoreIcons } from './enumTypes'
import { Link } from './TethrApi'

export enum ScoreColors {
    Red = "Red",
    Orange = "Orange",
    Yellow = "Yellow",
    Green = "Green",
    Olive = "Olive",
}

export interface ScoreValue {
    value: number
    color: ScoreColors
    label?: string
    icon?: ScoreIcons
}

export interface RootScore{
    id: string
    details?: ScoreDetails
    value?: ScoreValue
    components?: ComponentScore[]
    replaceAllComponents?: boolean
    timestampMs: number
    history?: ScoreHistory[] // TODO: Server is not sending this yet.
}

export interface ComponentScore{
    id: string
    details?: ScoreDetails
    value?: ScoreValue
}

export interface ScoreHistory {
    timestampMs: number
    value: number
    color?: ScoreColors
    label?: string
}

export interface ScoreDetails {
    scoreId: string
    orderIndex: number
    type: string
    displayName: string
    inScope: boolean
    recommendation?: string // Is Markdown
    links: Link[]
    //startMs?: number
    //endMs?: number
    renderOptions?: RenderOptions
}

export interface RenderOptions {
    min?: number,
    max?: number,
    precision?: number,
    hideValue?: boolean,
    gradient?: GradientConfig
}

export interface GradientConfig {
    ranges?: GradientValue[],
    lower: GradientValue,
    upper: GradientValue,
}

export interface GradientValue {
    value: number,
    color: ScoreColors,
}
