import { useSelector } from "react-redux";
import { InteractionStatus } from "../Models/enumTypes";
import { RootState } from "../store";

const AlertPopupContainer: React.FC = () => {
  const status = useSelector((state: RootState) => state.interaction.details?.status);

  if(status !== InteractionStatus.InProgress)
    return (<></>);

  return (
    <div id='toast-bottom-right' className='fixed w-xs md:w-md space-x-4 right-5 bottom-5' role='alert'>
      <ul className="space-y-3" style={{minWidth : '20rem'}}>
   {/*      <EventItem isAlert name='Customer verification Issues' text='view the troubleshooting guide' level={EventLevels.Info} />
        <EventItem isAlert name='Consectetur adipiscing' text='Est sit amet facilisis magna etiam tempor orci eu lobortis.' level={EventLevels.None} />
        <EventItem isAlert name='Grate job!' text='You handled that problem well!' level={EventLevels.Success} />
        <EventItem isAlert name='Supervisor alerted' text='Customer harassing agent' level={EventLevels.Alert} /> */}
      </ul>
    </div>
  );
};

export default AlertPopupContainer;
