import DialogBar from "./DialogBar";
import Score from "./Score";
import { useSelector } from "react-redux";
import { RootState } from "../store";

class MetricsProps {
  hideGraph?: boolean = false;
}

const Metrics: React.FC<MetricsProps> = (props) => {
  const scores = useSelector((state: RootState) => state.interaction.scores);

  return (
    <div className='grid grid-cols-1 gap-3'>
      <div className='font-medium'>Metrics</div>
      {scores.map((score) => {
        return <Score key={score.id} data={score} disableGraph={props.hideGraph} />;
      })}
      <Dialog />
    </div>
  );
}

function Dialog() {
  return (
    <div className='border-gray-200 rounded-lg border-2  '>
      <div className='text-gray-500 text-sm font-normal pl-2 pt-2 stroke-gray-500 flex items-center'>
        <div className='w-5 h-5 mr-1 inline-block'>
          <svg fill='none' strokeWidth='2' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' aria-hidden='true'>
            <path strokeLinecap='round' strokeLinejoin='round' d='M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z'></path>
          </svg>
        </div>
        <div className='inline-block'>Talk and silence time</div>
      </div>
      <DialogBar />
    </div>
  );
}

export default Metrics;
