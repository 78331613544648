import { Fragment } from "react";
import { useSelector } from "react-redux";
import { UtteranceBadge } from "../../Models/CompositeModels";
import { RootState } from "../../store";
import AlertItem from "./AlertItem";
import { TranscriptContainerProps } from "./TranscriptContainer";
import Utterance from "./Utterance";

export interface TranscriptProps {
  hideEvents?: boolean;
}

const Transcript: React.FC<TranscriptProps & TranscriptContainerProps> = (props) => {
  const timeLineEvents = useSelector((state: RootState) => state.interaction.timeLineEvents);
  return (
    <ol className={props.hideEvents ? 'space-y-1' : 'space-y-3'}>
      {timeLineEvents.map((tle) => {
        if (tle.utterance) {
          return (
            <Utterance
              key={tle.id}
              condensed={props.hideEvents}
              data={tle.utterance}
              name={tle.participantName ?? "Unknown"}
              badges={tle.events?.map((ev) => {
                return { key: ev.id, text: ev.displayName, eventId: ev.eventId, type: ev.type } as UtteranceBadge;
              })}
            />
          );
        } else if (tle.events && !tle.utterance && !props.hideEvents) {
          return (
            <Fragment key={tle.id}>
              {tle.events.map((ev) => {
                return <AlertItem key={ev.id} inLine={true} disableLinks={props.disableLinks} data={ev} />;
              })}
            </Fragment>
          );
        } else {
          return <Fragment key={tle.id} />; // Should never happen
        }
      })}
    </ol>
  );
};

export default Transcript;
