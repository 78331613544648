import { useSelector } from "react-redux";
import { UtteranceBadge } from "../../Models/CompositeModels";
import * as Models from "../../Models/TethrApi";
import { InteractionStatus } from "../../Models/enumTypes";
import displaySeconds, {formatDurationShort} from "../../displaySeconds";
import { RootState } from "../../store";

interface UtteranceProps {
  name: string;
  badges?: UtteranceBadge[];
  data: Models.Utterance;
  condensed?: boolean;
}

const Utterance: React.FC<UtteranceProps> = (props) => {
  const nowSeconds = useSelector((state: RootState) => state.interaction.nowSeconds);
  const inProgress = useSelector((state: RootState) => state.interaction.details?.status) === InteractionStatus.InProgress;

  if (props.condensed) {
    const timeDisplay = formatDurationShort(props.data.startMs / 1000);
    return (
      <li className='animate-fade'>
        <div className=''>
        <span className='text-xs font-normal text-gray-400 whitespace-nowrap'>{timeDisplay} </span>
          <span className='font-semibold text-sm text-gray-800 whitespace-nowrap'>{props.name}: </span>
          <span className='text-gray-500 text'>{props.data.content}</span>
        </div>
      </li>
    );
  }

  const timeDisplay = displaySeconds(inProgress ? nowSeconds - props.data.startMs / 1000 : props.data.startMs / 1000, inProgress);
  return (
    <li className='animate-fade'>
      <div className='flex justify-between'>
        <div>
          <div className='font-semibold mr-2 text-sm text-gray-800 inline-block'>{props.name}</div>
          {props.badges &&
            props.badges.map((badge) => {
              return (
                <div key={badge.key} data-event-type={badge.type} data-event-id={badge.eventId}  className='inline-block px-1.5 mr-1 bg-tethr-blue-light rounded-md text-sm'>
                  {badge.text}
                </div>
              );
            })}
        </div>
        <div className='text-sm font-normal text-gray-400'>{timeDisplay}</div>
      </div>
      <div className='text-gray-500 text'>{props.data.content}</div>
    </li>
  );
};

export default Utterance;
