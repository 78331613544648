import NavHeader from "../Components/Nav";
import InteractionDetails from "../Components/InteractionDetails";
import Metrics from "../Components/Metrics";
import Guides from "../Components/Guides";
import Recap from "../Components/Recap";
import TranscriptContainer from "../Components/Timeline/TranscriptContainer";
import AlertPopupContainer from "../Components/AlertPopupContainer";
import ScrollToBottom from "react-scroll-to-bottom";

import { connect } from "react-redux";
import Loading from "../Components/Loading";
import AppSettings from "../AppSettings";

function LiveInteractionUi() {
  const currentSettings = AppSettings();

  return (
    <div className='text-gray-900  '>
      {currentSettings.showNavBar && <NavHeader />}

      {currentSettings.loading ? (
        <Loading />
      ) : (
        <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 pt-1 pl-4 h-[calc(100vh-2.5rem)] space-y-3 sm:space-y-0'>
          <div className='space-y-3 sm:border-r-2 border-gray-200 pr-4 sm:pr-5 pb-5 sm:overflow-x-auto '>
            <InteractionDetails />
            <Metrics />
            <Guides />
          </div>
          {currentSettings.inProgress ? (
            <ScrollToBottom className='pl-4 sm:pl-5 pb-5 sm:overflow-x-auto lg:col-span-2' scrollViewClassName='space-y-3 pr-4 sm:pr-5'>
              <Recap />
              <TranscriptContainer disableLinks />
              <AlertPopupContainer />
            </ScrollToBottom>
          ) : (
            <div className='pl-4 sm:pl-5 pb-5 sm:overflow-x-auto space-y-3 pr-4 sm:pr-5 lg:col-span-2'>
              <Recap />
              <TranscriptContainer disableLinks />
              <AlertPopupContainer />
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default connect()(LiveInteractionUi);
