function formatDuration(totalSeconds: number, live: boolean = false) {
  if (live) {
    if (totalSeconds < 20) {
      return "now";
    } else if (totalSeconds < 60) {
      return "moments ago";
    }
  }

  if (totalSeconds < 1) {
    return "0 secs";
  }

  const days = Math.floor(totalSeconds / 86400);
  const hours = Math.floor((totalSeconds % 86400) / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const sec = Math.floor(totalSeconds % 60);

  let result = "";

  if (days === 0 && hours === 0) {
    if (minutes > 0) {
      result += minutes > 1 ? `${minutes} mins, ` : `${minutes} min, `;
    }

    if (!live) {
      // Show seconds only if we are under 10 minutes
      if (totalSeconds < 600 && sec > 0) {
        result += sec > 1 ? `${sec} secs` : `${sec} sec`;
      }
    }
  } else {
    if (days > 0) {
      result += `${days}d, `;
    }
    if (hours > 0) {
      result += `${hours}h, `;
    }
    // If we are over a hour, use short form.
    if (minutes > 0) {
      result += `${minutes}m, `;
    }
  }

  // Remove trailing comma and space, if any
  result = result.replace(/,\s*$/, "");

  if (live) {
    result += " ago";
  }

  return result;
}

export function formatDurationShort(totalSeconds: number) {
  const days = Math.floor(totalSeconds / 86400);
  const hours = Math.floor((totalSeconds % 86400) / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const sec = Math.floor(totalSeconds % 60);

  if (days > 0) {
    return `${days.toString().padStart(2,'0')}d ${hours.toString().padStart(2,'0')}:${minutes.toString().padStart(2,'0')}`;
  } else if (hours > 0 || days > 0) {
    return `${hours.toString().padStart(2,'0')}:${minutes.toString().padStart(2,'0')}:${sec.toString().padStart(2,'0')}`;
  } else {
    return `${minutes.toString().padStart(2,'0')}:${sec.toString().padStart(2,'0')}`;
  }
}

export default formatDuration;
