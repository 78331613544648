import { useSelector } from "react-redux";
import displaySeconds from "../../displaySeconds";
import { InteractionStatus } from "../../Models/enumTypes";
import { RootState } from "../../store";
import { EventIcon, GetEventColor } from "./EventComponents";
import RecommendationBtn from "../RecommendationBtn";

const Events: React.FC = () => {
  const events = useSelector((state: RootState) => state.interaction.events);
  const nowSeconds = useSelector((state: RootState) => state.interaction.nowSeconds);
  const inProgress = useSelector((state: RootState) => state.interaction.details?.status) === InteractionStatus.InProgress;
  const participants = useSelector((state: RootState) => state.interaction.participants);

  return (
    <div className='pl-3 '>
      <ol className='space-y-2'>
        {events.map((event) => {
          const eventColor = GetEventColor(event.level);
          let participantLabel = null;
          if (event.participantId !== undefined) {
            const participant = participants[event.participantId];
            if (participant) {
              participantLabel = participant.displayName;
            } else {
              participantLabel = "Unknown"; // We don't have the participant in the store yet
            }
          } else {
            // No participant ID, means it's something Tethr detected or can't be attributed to a participant
            participantLabel = "Tethr AI";
          }

          const timeDisplay = displaySeconds(inProgress ? nowSeconds - event.startMs / 1000 : event.startMs / 1000, inProgress);

          return (
            <li key={event.id} data-event-type={event.type} data-event-id={event.eventId} className={`relative border-l-2 border-${eventColor.dark} pl-6`}>
              <div>
                <span
                  className={`absolute flex items-center justify-center w-6 h-6 -left-3.5 top-2 bg-${eventColor.light} text-${eventColor.dark} rounded-full ring-8 ring-white`}
                >
                  <EventIcon level={event.level} isSystemParticipant={event.participantId === undefined} className='w-4 h-4' />
                </span>
                <h3 className={`flex items-baseline justify-between mb-1 text-lg font-semibold text-gray-900 pt-1 border-t-2 border-gray-100`}>
                  <span>{event.displayName} {event.recommendation !== undefined && event.participantId === undefined && (<RecommendationBtn text={event.recommendation} iconClassName="w-4 h-4 fill-current" />)}</span>
                  <span className='text-sm font-normal text-gray-400'>
                    <span>{timeDisplay}</span> | <span className='w-7'> {participantLabel}</span>
                  </span>
                </h3>

                {event.verbatim && <blockquote className=' text-sm italic font-medium text-gray-800'>"{event.verbatim}"</blockquote>}
              </div>
            </li>
          );
        })}
      </ol>
    </div>
  );
};

export default Events;
