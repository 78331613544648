export enum CheckListStatus {
    Open = "Open",
    Complete = "Complete",
    Missed = "Missed"
}

export enum InteractionStatus {
    InProgress = "InProgress",
    Concluded = "Concluded",
    Complete = "Complete"
}

export enum InteractionTypes {
    Call = "Call",
    Chat = "Chat",
}

export enum InteractionDirection {
    Unknown = "Unknown",
    Inbound = "Inbound",
    Outbound = "Outbound",
    Internal = "Internal",
}

export enum ScoreIcons
{
    None = "None",
    Neutral = "Neutral",
    Negative = "Negative",
    Positive = "Positive"
}


export enum ChangeType {
    InScope = "InScope",
    OutOfScope = "OutOfScope",
    Remove = "Remove"
}

export enum EventLevels
{
    None = "None", // Default, It's something that was worth noting, but not important enough to be really be called out on it's own.
    Info = "Information", // Is something that could be helpful to remember, along the lines of a note on why a customer is call, or a reminder on how to do something like a link to a KB article.
    Success = "Success", // Something went well, or was completed successfully.
    Warning = "Warning", // There is a potential issue, or a corse of action is being taken that should be altered.
    Alert = "Alert" // Something went wrong, or is going wrong, and is beeing ecilated to a higher level.
}