import React, { useRef, useLayoutEffect, useState, useEffect } from "react";
import "tailwindcss/tailwind.css";
import { connect, useSelector } from "react-redux";
import { RootState } from "../store";

const DialogBar: React.FC = () => {
  const dialogTalkTime = useSelector((state: RootState) => state.interaction.dialogTalkTime);

  let agent = dialogTalkTime.internalTalkPercentage;
  let silence = dialogTalkTime.silencePercentage;
  let customer = dialogTalkTime.externalTalkPercentage;

  let total = agent + silence + customer;

  const agentPercentage = total === 0 ? 0 : (agent / total) * 100;
  const silencePercentage = total === 0 ? 0 : (silence / total) * 100;
  const customerPercentage = total === 0 ? 0 : (customer / total) * 100;
  const [silenceLabelLeft, setSilenceLabelLeft] = useState(0);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const agentLabelRef = useRef<HTMLDivElement>(null);
  const silenceLabelRef = useRef<HTMLDivElement>(null);
  const customerLabelRef = useRef<HTMLDivElement>(null);
  const barRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleResize() {
      // TODO: BUG: Need to disable the animation when resizing the window
      setScreenWidth(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  });

  useLayoutEffect(() => {
    if (agentLabelRef.current && silenceLabelRef.current && customerLabelRef.current && barRef.current) {
      const agentLabelWidth = agentLabelRef.current.getBoundingClientRect().width;
      const silenceLabelWidth = silenceLabelRef.current.getBoundingClientRect().width;
      const customerLabelWidth = customerLabelRef.current.getBoundingClientRect().width;
      const barWidth = barRef.current.getBoundingClientRect().width;

      // Calculate the position of the Silence label using widths only, as we have animations
      // so the current positions are moving.

      // Compute the position of the Silence bar will be
      // this is the left edge of the bar is the width of the first bar (agentPercentage)
      const silenceBarLeft = barWidth * (agentPercentage / 100);

      const minGap = 8;
      let newSilenceLabelLeft = silenceBarLeft;
      const customerLabelLeft = barWidth - customerLabelWidth;

      // Place the Silence label so that it is not overlapping the Agent or Customer labels
      if (silenceBarLeft - agentLabelWidth < minGap) {
        // Ok our bar is under the agent label, so we need to move the silence label to the right
        newSilenceLabelLeft = agentLabelWidth + minGap;
      } else if (customerLabelLeft - minGap < silenceBarLeft + silenceLabelWidth) {
        // Our Silence label is overlapping the customer label, so we need to move it to the left
        newSilenceLabelLeft = customerLabelLeft - minGap - silenceLabelWidth;
      }

      if (silenceLabelLeft !== newSilenceLabelLeft) {
        setSilenceLabelLeft(newSilenceLabelLeft);
      }
    }
  }, [agentPercentage, silencePercentage, customerPercentage, screenWidth, silenceLabelLeft]);

  if (total === 0) {
    return <div className='pl-2 py-4 text-sm text-gray-600'>loading...</div>;
  }

  return (
    <div className='flex flex-col items-stretch p-2'>
      <div className='flex w-full text-gray-500 text-xs relative'>
        <div>
          <p ref={agentLabelRef} className='text-left pr-1'>
            Agent
          </p>
        </div>
        <div
          ref={silenceLabelRef}
          className='absolute'
          style={{
            left: silenceLabelLeft,
            transition: "left 0.5s cubic-bezier(0.4, 0, 0.2, 1)",
          }}
        >
          <p className='text-left'>Silence</p>
        </div>
        <div ref={customerLabelRef} className='absolute right-0'>
          <p className='text-right pl-1'>Customer</p>
        </div>
      </div>
      <div ref={barRef} className='flex h-5 w-full'>
        <div style={{ width: "0.5rem" }} className='bg-blue-700 rounded-l-lg'></div>
        <div style={{ width: `${agentPercentage}%`, transition: "width .25s cubic-bezier(0.4, 0, 0.2, 1)" }} className='bg-blue-700'></div>
        <div style={{ width: `${silencePercentage}%`, transition: "width .25s cubic-bezier(0.4, 0, 0.2, 1)" }} className='bg-red-400 ml-px mr-px'></div>
        <div style={{ width: `${customerPercentage}%`, transition: "width .25s cubic-bezier(0.4, 0, 0.2, 1)" }} className='bg-gray-300'></div>
        <div style={{ width: "0.5rem"}} className='bg-gray-300 rounded-r-lg'></div>
      </div>
    </div>
  );
};

export default connect()(DialogBar);
