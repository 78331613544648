import Logo from "../Assets/Logo";
import { connect, useSelector } from "react-redux";
import { Link, useSearchParams } from "react-router-dom";
import { Fragment } from "react";
import AppSettings from "../AppSettings";
import { RootState } from "../store";
import displaySeconds from "../displaySeconds";

function NavHeader() {
  const currentSettings = AppSettings();
  const nowSeconds = useSelector((state: RootState) => state.interaction.nowSeconds);
  const [searchParams] = useSearchParams();
  const switcher = searchParams.get("switcher") === "false" ? false : true;

  const RenderState: React.FC = () => {
    if (currentSettings.loading) {
      return (
        <div className='flex'>
          <div className='text-xl pr-2 font-light'>Waiting</div>
          <div></div>
        </div>
      );
    }

    if (currentSettings.inProgress) {
      return (
        <div className='flex'>
          <div className='text-xl pr-2 font-light'>live <span className="text-sm font-normal text-gray-400">for {displaySeconds(nowSeconds)}</span></div>
          <div>
            <div className='w-5 h-5 bg-blue-100 rounded-full translate-y-1/4'>
              <div className='w-5 h-5 scale-75 bg-blue-300 rounded-full'>
                <div className='w-5 h-5 scale-50 bg-blue-600 rounded-full'></div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className='flex'>
          <div className='text-xl pr-2 font-light'>Competed Call</div>
          <div>
            <div className='stroke-tethr-blue w-5 h-5 bg-tethr-blue-light rounded-full translate-y-1/4'>
              <svg fill='none' strokeWidth='3' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' aria-hidden='true'>
                <path strokeLinecap='round' strokeLinejoin='round' d='M4.5 12.75l6 6 9-13.5'></path>
              </svg>
            </div>
          </div>
        </div>
      );
    }
  };

  return (
    <nav className='bg-white boorder-0 sm:border-b-2 border-gray-200h-10 overflow-hidden p-1 px-3'>
      <div className='flex items-center justify-between'>
        <div className='flex items-center justify-between space-x-3'>
          <div className='w-16'>
            <Logo />
          </div>
          {switcher &&
            (currentSettings.isAgent ? (
              <Fragment>
                <Link to='/Supervisor' className='text-sm font-medium text-gray-800'>
                  Supervisor
                </Link>
                <div className='text-sm font-medium text-gray-400 border-b-2 border-gray-400'>Agent</div>
              </Fragment>
            ) : (
              <Fragment>
                <div className='text-sm font-medium text-gray-400 border-b-2 border-gray-400'>Supervisor</div>
                <Link to='/Agent' className='text-sm font-medium text-gray-800'>
                  Agent
                </Link>
              </Fragment>
            ))}
        </div>
        <RenderState />
      </div>
    </nav>
  );
}

export default connect()(NavHeader);
