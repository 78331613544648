import React, { useState } from "react";
import { CheckListStatus } from "../Models/enumTypes";
import { useSelector } from "react-redux";
import { RootState } from "../store";
import { CheckList, CheckListItem } from "../Models/TethrApi";
import { ChevronDownIcon, MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { CheckCircleIcon, MinusCircleIcon } from "@heroicons/react/20/solid";
import { Tooltip } from "flowbite-react";

function Guides() {
  const checkLists = useSelector((state: RootState) => state.interaction.checkLists);

  if(!checkLists || checkLists.length === 0){
    return null;
  }
  return (
    <div className='grid grid-cols-1 gap-3'>
      <div className='font-medium'>Guides</div>
      {checkLists.map((checkList) => {
        return <Guide key={checkList.id} data={checkList} />;
      })}
    </div>
  );
}

enum openStates {
  auto,
  open,
  closed,
}

const Guide: React.FC<GuideProps> = (props) => {
  const [openState, setOpenState] = useState(openStates.auto);

  const countOfComplete = props.data.items.filter((item) => item.status === CheckListStatus.Complete).length;
  const countOfItems = props.data.items.length;
  const isComplete = !props.data.items.some((item) => item.status === CheckListStatus.Open);
  const isOpen = openState === openStates.open || (openState === openStates.auto && !isComplete);

  return (
    <div className='border-gray-200 rounded-lg border-2 '>
      <h2>
        <button
          onClick={() => {
            setOpenState(isOpen ? openStates.closed : openStates.open);
          }}
          type='button'
          className='flex items-center justify-between w-full p-2 font-medium text-left bg-gray-100 text-gray-900 border-0 rounded-t-md border-gray-200 focus:ring-2 focus:ring-gray-100 hover:bg-gray-200'
        >
          <span className='flex items-center'>
            <StatusIcon status={!isComplete ? CheckListStatus.Open : countOfComplete === countOfItems ? CheckListStatus.Complete : CheckListStatus.Missed} />
            {props.data.details?.displayName} ({countOfComplete}/{countOfItems})
            {props.data.details?.description && (
              <Tooltip content={props.data.details?.description}>
                <MagnifyingGlassIcon className='pl-2 h-5 w-5 text-gray-500' />
              </Tooltip>
            )}
          </span>
          <ChevronDownIcon aria-label={isOpen ? "collapse guide" : "expand guide"} className={`w-6 h-6 ${isOpen ? "" : "rotate-180"} shrink-0 transition-transform`} />
        </button>
      </h2>
      <div className='mt-2 mb-2 border-b-0'>
        {props.data.items.map((item) => {
          return <GuideItem key={item.id} isOpen={isOpen} data={item} />;
        })}
      </div>
    </div>
  );
};

const GuideItem: React.FC<GuideItemProps> = (props) => {
  const hide = !props.isOpen && props.data.status !== CheckListStatus.Missed ? "hidden" : "";
  return (
    <div className={`flex justify-between py-1 px-2 ${hide}`}>
      <span className='flex items-center'>
        <StatusIcon status={props.data.status} />
        {props.data.displayName}
      </span>
      <span>
        {props.data.description && (
          <Tooltip content={props.data.description} placement={"left-start"}>
            <MagnifyingGlassIcon className='h-6 w-6 text-gray-500' />
          </Tooltip>
        )}
      </span>
    </div>
  );
};

interface GuideProps {
  data: CheckList;
}
interface GuideItemProps {
  isOpen: boolean;
  data: CheckListItem;
}

interface StatusIconProps {
  status: CheckListStatus | undefined;
}

const StatusIcon: React.FC<StatusIconProps> = (props) => {
  switch (props.status) {
    case CheckListStatus.Missed:
      return <MinusCircleIcon aria-label="Step Missed" className='h-6 w-6 text-red-500 pr-1' />;
    case CheckListStatus.Complete:
      return <CheckCircleIcon aria-label="Step Completed" className='h-6 w-6 text-green-400 pr-1' />;
    case CheckListStatus.Open:
    default:
      return <MinusCircleIcon aria-label="To Do" className='h-6 w-6 text-gray-300 pr-1' />;
  }
};

export default Guides;
