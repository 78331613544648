import { useRouteError } from "react-router-dom";

type ErrorResponse = {
    data: any;
    status: number;
    statusText: string;
    message?: string;
  };

  const errorCheck = (error: any): error is ErrorResponse => {
    return "data" in error && "status" in error && "statusText" in error;
  };
  
export default function ErrorPage() {
  const error = useRouteError();
  console.error(error);

  return (
    <div id="error-page">
      <h1>Oops!</h1>
      <p>Sorry, an unexpected error has occurred.</p>
      <p>
        {errorCheck(error) ? (<i>{error.statusText || error.message}</i>) : (<i>Unknown Errro</i>) }
      </p>
    </div>
  );
}