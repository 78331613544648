import { XMarkIcon } from "@heroicons/react/20/solid";
import { useState } from "react";
import { GetEventColor, EventIcon, EventBtnGroup, EventProps } from "./EventComponents";
import RecommendationBtn from "../RecommendationBtn";

export const AlertItem: React.FC<EventProps> = (props) => {
  const [userClosed, setUserClosed] = useState(false); // TODO: Save to local storage
  if (userClosed) {
    return <></>;
  }

  const { light: bgColor, dark: txtColor } = GetEventColor(props.data.level);

  // TODO: If is Alert, then atuo close after a set time
  // CONSIDER: Should time differ based on level?

  const showVerbatim = props.data.verbatim !== undefined && (!props.inLine || props.data.participantId === undefined);
  const showBtnGroup = !props.inLine && !props.disableLinks;

  return (
    <li className={`bg-${bgColor} text-${txtColor} p-3 rounded-md flex ${props.isAlert ? "shadow-lg shadow-gray-500 animate-fade" : ""}`}>
      <div className="w-full">
        <div className="flex justify-between">
          <div className='flex items-start '>
            <EventIcon level={props.data.level} isSystemParticipant={props.data.participantId === undefined} />
            <div className='ml-2'>
              <span className='font-semibold pr-1'>
                {props.data.displayName}
                {props.inLine && props.data.description && ":"}
              </span>
              {props.inLine && props.data.description && <div>{props.data.description}</div>}
            </div>
          </div>
          {!showBtnGroup && props.data.recommendation !== undefined && props.data.participantId === undefined && (<RecommendationBtn text={props.data.recommendation} />)}
        </div>
        {!props.inLine && props.data.description && <div>{props.data.description}</div>}
        {showVerbatim && <blockquote className='italic font-semibold text-gray-900 pt-2'>"{props.data.verbatim}"</blockquote>}
        {showBtnGroup && <EventBtnGroup data={props.data} />}
      </div>
      {props.isAlert && (
        <button
          type='button'
          onClick={() => setUserClosed(true)}
          className='ml-auto -mx-1.5 -my-1.5 rounded-lg focus:ring-2 p-1.5 inline-flex h-8 w-8 hover:bg-gray-200 focus:ring-gray-300'
        >
          <span className='sr-only'>Close</span>
          <XMarkIcon className='h-6 w-6' />
        </button>
      )}
    </li>
  );
};

// TODO: Add a button to close during call for

export default AlertItem;
