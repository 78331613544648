import { Fragment } from "react";
import { connect, useSelector } from "react-redux";
import { RootState } from "../../store";
import AlertItem from "./AlertItem";

const AgentAssist: React.FC = () => {
  const timeLineEvents = useSelector((state: RootState) => state.interaction.timeLineEvents);
  var alertCount = timeLineEvents.filter((tle) => tle.events && !tle.utterance).reduce((acc, tle) => acc + tle.events!.length, 0);

  return (
    <div className='space-y-3'>
      <div className='hidden sm:block font-medium'>Agent assist ({alertCount})</div>
      <div className='pt-3'>
        <ul className='space-y-3'>
          {timeLineEvents
            .filter((t) => t.events && !t.utterance)
            .map((tle) => {
              return (
                <Fragment key={tle.id}>
                  {tle.events!.map((ev) => {
                    return <AlertItem key={ev.id} data={ev} />;
                  })}
                </Fragment>
              );
            })}
        </ul>
      </div>
    </div>
  );
};

export default connect()(AgentAssist);
