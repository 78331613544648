import NavHeader from "../Components/Nav";
import Metrics from "../Components/Metrics";
import Guides from "../Components/Guides";
import Recap from "../Components/Recap";
import AlertPopupContainer from "../Components/AlertPopupContainer";
import ScrollToBottom from "react-scroll-to-bottom";

import { connect, useSelector } from "react-redux";
import { RootState } from "../store";

import { useSearchParams } from "react-router-dom";
import { InteractionStatus } from "../Models/enumTypes";
import Loading from "../Components/Loading";
import AgentAssist from "../Components/Timeline/AgentAssist";
import Transcript from "../Components/Timeline/Transcript";

function LiveInteractionUi() {
  const loading = useSelector((state: RootState) => state.interaction.loading);
  const interactionStatus = useSelector((state: RootState) => state.interaction.details?.status);
  const inProgress = interactionStatus === InteractionStatus.InProgress;

  const [searchParams] = useSearchParams();
  const navBarFlag = searchParams.get("navbar");
  const navBar = navBarFlag === "false" ? false : true;

  return (
    <div className='text-gray-900  '>
      {navBar && <NavHeader />}

      {loading ? (
        <Loading />
      ) : inProgress ? (
        <div className='grid grid-cols-1 sm:grid-cols-2 pt-1 pl-4 h-[calc(100vh-2.5rem)] space-y-3 sm:space-y-0'>
          <div className='space-y-3 sm:border-r-2 border-gray-200 pr-4 sm:pr-5 pb-5 sm:overflow-x-auto '>
            <Recap />
            <Guides />
          </div>
          <ScrollToBottom className='pl-4 sm:pl-5 pb-5 sm:overflow-x-auto' scrollViewClassName='space-y-3 pr-4 sm:pr-5'>
            <AgentAssist />
            <AlertPopupContainer />
          </ScrollToBottom>
        </div>
      ) : (
        <div className='grid grid-cols-1 sm:grid-cols-2 pt-1 pl-4 h-[calc(100vh-2.5rem)] space-y-3 sm:space-y-0'>
          <div className='space-y-3 sm:border-r-2 border-gray-200 pr-4 sm:pr-5 pb-5 sm:overflow-x-auto '>
            <Recap />
            <Metrics hideGraph />
            <Guides />
          </div>
          <div className='pl-4 sm:pl-5 pb-5 sm:overflow-x-auto space-y-3 pr-4 sm:pr-5'>
            <Transcript disableLinks />
          </div>
        </div>
      )}
    </div>
  );
}

export default connect()(LiveInteractionUi);
