import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./index.css";
import SupervisorUI from "./Pages/Supervisor";
import AgentUI from "./Pages/Agent";
import reportWebVitals from "./reportWebVitals";
import ErrorPage from "./error-page";
import { Provider } from "react-redux";
import store from "./store/index";
import SseConnection from "./SseConnection";

// See https://reactrouter.com/en/main/start/tutorial
const router = createBrowserRouter([
  {
    path: "/",
    element: <SupervisorUI />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/Supervisor",
    element: <SupervisorUI />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/Agent",
    element: <AgentUI />,
    errorElement: <ErrorPage />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <RouterProvider router={router} />
      <SseConnection/>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
