import { configureStore } from '@reduxjs/toolkit'
import interactionDetailSlice from './interactionDetailSlice'

const store = configureStore({
  reducer: {
    interaction: interactionDetailSlice
  },
})

export default store
export type RootState = ReturnType<typeof store.getState>