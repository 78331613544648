import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import * as m from "../Models/TethrApi";
import * as c from "../Models/CompositeModels";
import { ChangeType, EventLevels, InteractionStatus } from "../Models/enumTypes";
import * as s from "../Models/Score";
import LiveInteraction from "../Models/TethrApi";

type InteractionDetailState = {
  loading: boolean;
  nowSeconds: number;
  id: string | null;
  details: m.InteractionDetails | null;
  participants: Record<number, m.Participant>;
  dialog: m.Dialog | null;
  utterances: m.Utterance[];
  scores: s.RootScore[];
  checkLists: m.CheckList[];
  events: m.Event[];
  dialogTalkTime: c.DialogTalkTime;
  summary: c.InteractionSummary;
  timeLineEvents: c.TimeLineEvent[];
  recapEvents: m.Event[];
};

const initialState: InteractionDetailState = {
  loading: true,
  nowSeconds: 0,
  id: null,
  details: null,
  participants: {},
  dialog: null,
  utterances: [],
  scores: [],
  checkLists: [],
  events: [],
  dialogTalkTime: { silencePercentage: 0, internalTalkPercentage: 0, externalTalkPercentage: 0 },
  summary: { agentParticipantId: null, customerParticipantId: null },
  timeLineEvents: [],
  recapEvents: [],
};

const resetState = (state: InteractionDetailState) => {
  state.id = null;
  state.nowSeconds = 0;
  state.details = null;
  state.participants = {};
  state.dialog = null;
  state.utterances = [];
  state.scores = [];
  state.events = [];
  state.checkLists = [];
  state.timeLineEvents = [];
  state.recapEvents = [];
  state.summary = { agentParticipantId: null, customerParticipantId: null };
  state.dialogTalkTime = { silencePercentage: 0, internalTalkPercentage: 0, externalTalkPercentage: 0 };
  state.loading = true;
};

export const interactionDetailSlice = createSlice({
  name: "interactionDetails",
  initialState,
  reducers: {
    timerTick: (state: InteractionDetailState) => {
      if (state.details?.status === InteractionStatus.InProgress) {
        var callStart = new Date(state.details.utcStart);
        var now = new Date();
        state.nowSeconds = Math.floor((now.getTime() - callStart.getTime()) / 1000);
      }
    },
    update: (state: InteractionDetailState, action: PayloadAction<LiveInteraction>) => {
      const e = action.payload;
      if ((state.id && state.id !== e.id) || e.isKeyFrame) {
        if (e.isKeyFrame) {
          console.log("IsKeyFrame, Clearing state.");
        } else {
          // TODO: request a key frame of the interaction.
          console.log("Interaction ID changed, without clearing state or getting Key Frame. Clearing state.");
        }
        resetState(state);
        state.id = e.id;
      }

      // TODO: Track that we didn't miss an update, or if we are getting an old one that we already have.
      // TODO: If we are missing a frame, we could hold a frame for a bit and see if we get the missing one, or just request a key frame
      // TODO: If we are getting an old frame, we should ignore it.
      // TODO: Sore items, like scores and checklists or anything with an OrderIndex so the UI doesn't have to do it on each update of the DOM.

      let shouldUpdateEventTimeline = false;

      if (e.details) {
        state.details = e.details;
        state.loading = false;
      }

      // If the Call ID changes, send clear to all other slicers.
      if (e.participants) {
        shouldUpdateEventTimeline = true;
        e.participants.forEach((p) => {
         
          if (!p.displayName) { // Display Name the role we show for the participant in the UI.
            p.displayName = p.isInternal ? "Agent" : "Customer";
          }
          state.participants[p.participantId] = p;
        });

        state.summary.agentParticipantId = null;
        state.summary.customerParticipantId = null;
        let fallbackExternalParticipantId: number | null = null;
        Object.entries(state.participants).forEach((kv) => {
          if (!kv[1].isInternal) {
            state.summary.customerParticipantId = Number(kv[0]);
          } else {
            // capture the first external participant as a fallback.
            if (fallbackExternalParticipantId === null) {
              fallbackExternalParticipantId = Number(kv[0]);
            }

            if (kv[1].refTypeId === "Agent") {
              state.summary.agentParticipantId = Number(kv[0]);
            }
          }
        });

        if (state.summary.agentParticipantId === undefined || state.summary.agentParticipantId === null) {
          state.summary.agentParticipantId = fallbackExternalParticipantId;
        }
      }

      if (e.dialog) {
        state.dialog = e.dialog;
        const newDialogValue: c.DialogTalkTime = {
          silencePercentage: e.dialog.silencePercentage,
          internalTalkPercentage: 0,
          externalTalkPercentage: 0,
        };

        e.dialog.participants.forEach((dp) => {
          var p = state.participants[dp.participantId];
          if (p) {
            if (p.isInternal) {
              newDialogValue.internalTalkPercentage += dp.talkPercentage;
            } else {
              newDialogValue.externalTalkPercentage += dp.talkPercentage;
            }
          }
        });

        state.dialogTalkTime = newDialogValue;
      }

      if (e.utterances) {
        shouldUpdateEventTimeline = true;
        state.utterances = updateUtteranceReduce(e.utterances, state.utterances);
      }

      if (e.scores) {
        state.scores = updateScoreReduce(e.scores, state.scores);
      }

      if (e.checkLists) {
        state.checkLists = updateCheckListReduce(e.checkLists, state.checkLists);
      }

      if (e.events) {
        shouldUpdateEventTimeline = true;
        state.events = updateEventReduce(e.events, state.events);
      }

      if (e.scopeUpdate) {
        if (e.scopeUpdate.events) {
          e.scopeUpdate.events.forEach((scopeChange) => {
            const e = state.events.find((s) => s.id === scopeChange.id);
            if (e) {
              if (scopeChange.state === ChangeType.OutOfScope) {
                e.inScope = false;
              } else if (scopeChange.state === ChangeType.InScope) {
                e.inScope = true;
              } else if (scopeChange.state === ChangeType.Remove) {
                state.events = state.events.filter((s) => s.id !== scopeChange.id);
              }
            } else {
              console.warn("Updating Event Scope, Could not find event with id " + scopeChange.id);
            }
          });

          if (e.scopeUpdate.checkLists) {
            console.log("TODO: Updating CheckList Scope");
          }

          if (e.scopeUpdate.scores) {
            console.log("TODO: Updating Score Scope");
          }
        }
      }

      if (shouldUpdateEventTimeline) {
        // TODO: Take events that have a level of NONE and add them to the events under the utterance that they are in.
        // this will turn them into badages on the utterace.

        state.recapEvents = state.events.filter((ev) => ev.type === "Summary");

        state.timeLineEvents = state.utterances
          .map((u) => {
            return {
              id: u.id,
              utterance: u,
              startMs: u.startMs,
              participantName: u.participantId !== undefined ? state.participants[u.participantId]?.displayName : undefined,
              participantId: u.participantId,
            } as c.TimeLineEvent;
          })
          .concat(
            state.events
              .filter((ev) => ev.type !== "Summary" && ev.level && ev.level !== EventLevels.None) // Filter types we don't want in the timeline.
              .map((e) => {
                return {
                  id: e.id,
                  events: [e],
                  startMs: e.startMs,
                  participantName: e.participantId !== undefined ? state.participants[e.participantId]?.displayName : undefined,
                  participantId: e.participantId,
                } as c.TimeLineEvent;
              })
          )
          .sort((a, b) => a.startMs - b.startMs);

        // Event with level of None, should be added to the utterance that they are in as a badge.
        // Right now we are going to add them based on the utterance who's start time is closest to the event.
        state.events
          .filter((ev) => (!ev.level || ev.level === EventLevels.None) && ev.type !== "Summary")
          .forEach((ev) => {
            // look at state.timeLineEvents that have an utterance and whos start time is before the event's time
            // then find the utterance with the closest start time to the event.
            const timeLineEvent = state.timeLineEvents
              .filter((u) => u.utterance !== undefined && u.startMs <= ev.startMs && u.participantId === ev.participantId)
              .sort((a, b) => b.startMs - a.startMs)[0];
            // add the event to the events array of the timeLineEvents, and create an array if it doesn't exist.
            if (timeLineEvent) {
              if (!timeLineEvent.events) {
                timeLineEvent.events = [];
              }
              timeLineEvent.events.push(ev);
            }
          });
      }
    },
    clear: (state: InteractionDetailState) => {
      console.info("Clearing interaction state");
      resetState(state);
    },
  },
});

// Action creators are generated for each case reducer function
export const { update, clear, timerTick } = interactionDetailSlice.actions;

export default interactionDetailSlice.reducer;

function updateUtteranceReduce(newUtterances: m.Utterance[], existingUtterances: m.Utterance[]): m.Utterance[] {
  return newUtterances
    .reduce((acc, newUtterance) => {
      const existingUtteranceIndex = acc.findIndex((p) => p.id === newUtterance.id);

      if (existingUtteranceIndex !== -1) {
        acc[existingUtteranceIndex] = newUtterance;
      } else {
        acc.push(newUtterance);
      }

      return acc;
    }, existingUtterances)
    .sort((a, b) => a.startMs - b.startMs);
}

function updateScoreReduce(newScores: s.RootScore[], existingScores: s.RootScore[]): s.RootScore[] {
  return newScores.reduce((acc, newScore) => {
    const existingScoreIndex = acc.findIndex((p) => p.id === newScore.id);

    if (existingScoreIndex !== -1) {
      var existingScore = acc[existingScoreIndex];

      if (newScore.value) existingScore.value = newScore.value;

      if (newScore.details) {
        existingScore.details = newScore.details;
      }

      if (newScore.history) {
        // If we have a new score history, it means the server has a new history for us.
        existingScore.history = newScore.history;
      } else if (newScore.value) {
        // timeStampMs should be required, but just in case
        if (!existingScore.history) {
          existingScore.history = [];
        }

        // TODO: Check that we are not adding two of the same value in a row.
        // or that have the same timestamp.
        // TODO: check if we need to sort the values, could be out of order.
        existingScore.history.push({
          timestampMs: newScore.timestampMs,
          value: newScore.value.value,
          color: newScore.value.color,
          label: newScore.value.label,
        });
      }

      if (newScore.components) {
        if (newScore.replaceAllComponents || !existingScore.components || existingScore.components.length === 0) {
          existingScore.components = newScore.components;
        } else {
          existingScore.components = updateComponentScoreReduce(existingScore.components, newScore.components);
        }
      }
    } else {
      acc.push(newScore);
    }

    return acc;
  }, existingScores);
}

function updateCheckListReduce(newCheckLists: m.CheckList[], existingCheckLists: m.CheckList[]): m.CheckList[] {
  return newCheckLists.reduce((acc, newCheckList) => {
    const existingCheckListIndex = acc.findIndex((p) => p.id === newCheckList.id);

    if (existingCheckListIndex !== -1) {
      const c = acc[existingCheckListIndex];
      if (newCheckList.details) {
        c.details = newCheckList.details;
      }

      if (newCheckList.items) {
        if (newCheckList.replaceAllItems) {
          c.items = newCheckList.items;
        } else {
          c.items = updateCheckListItemReduce(newCheckList.items, c.items);
        }
      }
    } else {
      acc.push(newCheckList);
    }

    return acc;
  }, existingCheckLists);
}

function updateCheckListItemReduce(newCheckLists: m.CheckListItem[], existingCheckLists: m.CheckListItem[]): m.CheckListItem[] {
  return newCheckLists.reduce((acc, newCheckList) => {
    const existingCheckListIndex = acc.findIndex((p) => p.id === newCheckList.id);

    if (existingCheckListIndex !== -1) {
      acc[existingCheckListIndex] = newCheckList;
    } else {
      acc.push(newCheckList);
    }

    return acc;
  }, existingCheckLists);
}

function updateEventReduce(newEvents: m.Event[], existingEvents: m.Event[]): m.Event[] {
  return newEvents
    .reduce((acc, newEvent) => {
      const existingEventIndex = acc.findIndex((p) => p.id === newEvent.id);

      if (existingEventIndex !== -1) {
        acc[existingEventIndex] = newEvent;
      } else {
        acc.push(newEvent);
      }

      return acc;
    }, existingEvents)
    .sort((a, b) => a.startMs - b.startMs);
}

function updateComponentScoreReduce(existingComponents: s.ComponentScore[], newComponents: s.ComponentScore[]): s.ComponentScore[] {
  newComponents.forEach((newComponent) => {
    const existingComponentIndex = existingComponents.findIndex((p) => p.id === newComponent.id);
    if (existingComponentIndex !== -1) {
      const ec = existingComponents[existingComponentIndex];
      if (newComponent.value) {
        ec.value = newComponent.value;
      }

      if (newComponent.details) {
        ec.details = newComponent.details;
      }
    } else {
      existingComponents.push(newComponent);
    }
  });

  return existingComponents;
}
