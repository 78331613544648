import * as React from "react";
import { connect, useDispatch } from "react-redux";
import SseClient from "./Models/SseClient";
import LiveInteraction from "./Models/TethrApi";
import * as interaction from "./store/interactionDetailSlice";

function SseConnection() {
  const dispatch = useDispatch();
  React.useEffect(() => {
    // This app will run in an IFramed context, so we need to get the parent window to send the messages to us to what call to listen to.
    // TODO: hook up https://developer.mozilla.org/en-US/docs/Web/API/Window/postMessage from the partent to allow us to get the call ID.
    // Also review https://developer.mozilla.org/en-US/docs/Web/API/Window/postMessage#security_concerns, and see how we can allow for configuration of allowed sources.
    // The simplest check we can do is check the source is our partent window.
    //  See https://stackoverflow.com/questions/326069/how-to-identify-if-a-webpage-is-being-loaded-inside-an-iframe-or-directly-into-t

    // CONSIDER: The PostMessage processor may belong outside of the SseClient, so that we can use it for other things
    //  - Redirect to the home page if the call ends
    //  - Allowing the agent to look at other calls that are already completed

    //
    // Mock senario API server - Local Host
    // const endpoint =  "https://localhost:7010/Live/Interaction?id=cbw00000a"

    // Mock senario API server - Labs
    //const endpoint = "https://tethr-live-mockapi.azurewebsites.net/Live/Interaction?id=cbw00000a";

    // Live API server - Local (replay)
    // const endpoint = "https://localhost:7150/Api/Call/Live?id=8cd25e1b-61ca-4fc6-81fd-0f6997960fcb"
    // const endpoint = "https://localhost:7150/Api/Call/Live?id=bcc3bc95-cabf-41b2-832f-b73341b82fbf"
    // const endpoint = "https://localhost:7150/Api/Call/Live?id=0c66ea69-b36c-4501-a6e5-45f64cf2e40e"

    // Live API server - Glia auto swtich to live call
     const endpoint = "https://glia-audio-stream.labs.tethr.io/Api/Call/Live?id=live"
    // View live deployment
    //  https://witty-meadow-0eaf65a10.2.azurestaticapps.net
    dispatch(interaction.clear());
    const sseClient: SseClient = new SseClient(
      endpoint,
      (e: MessageEvent) => {
        console.log("onMessage", e.data);
      },
      (e: Event) => {
        console.log("onError", e);
      },
      (e: Event) => {
        console.log("onOpen", e);
      },
      (e: LiveInteraction) => {
        console.debug("onInteractionEvent", e);
        dispatch(interaction.update(e));
      }
    ).start();

    const interval = setInterval(() => {
        dispatch(interaction.timerTick());
    }, 1000);

    return () => {
        sseClient.stop();
        clearInterval(interval)
    }
  }, [dispatch]);

  return <></>;
}

export default connect()(SseConnection);
