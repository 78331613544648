import { FaceFrownIcon, FaceSmileIcon } from "@heroicons/react/24/outline";
import { FaceNutralIcon } from "../Assets/Icons";
import { RootScore, ScoreColors, ScoreDetails, ScoreValue } from "../Models/Score";
import { ResponsiveLine, Serie } from "@nivo/line";
import { ScoreIcons } from "../Models/enumTypes";
import RecommendationBtn from "./RecommendationBtn";

interface ScoreProps {
  data: RootScore;
  disableGraph?: boolean;
}

const Score: React.FC<ScoreProps> = (props) => {
  return (
    <div className='border-gray-200 rounded-lg border-2  pt-2 '>
      <ScoreLine value={props.data.value} details={props.data.details} />
      {props.disableGraph ? <div className='pb-2'> </div> : Chart(props.data)}
      {props.data.components && (
        <div className='border-gray-200 border-0 border-t-2 py-2 px-2 space-y-2'>
          {props.data.components.map((component) => (
            <ScoreLine key={component.id} value={component.value} details={component.details} />
          ))}
        </div>
      )}
    </div>
  );
};

interface ScoreLineProps {
  value?: ScoreValue;
  details?: ScoreDetails;
}

const ScoreLine: React.FC<ScoreLineProps> = (props) => {
  const label = props.value?.label;
  return (
    <div className='text-gray-500 text-sm font-normal pl-2 flex content-between justify-between'>
      <div className='flex content-start items-center'>
        <div className={`${ToColorClass(props.value?.color)} pr-1`}>
          <ScoreIcon icon={props.value?.icon} />
        </div>
        <div className='inline-block'>
          {props.details?.displayName}
          {label && ": "}
          {label && <span className={ToColorClass(props.value?.color)}>{label}</span>}
        </div>
      </div>
      <div>{props.details?.recommendation && <RecommendationBtn text={props.details.recommendation} />}</div>
    </div>
  );
};

export interface ScoreIconProps {
  icon?: ScoreIcons;
}

const ScoreIcon: React.FC<ScoreIconProps> = (props) => {
  // TODO: Add Icon to ScoreValue
  switch (props.icon) {
    case ScoreIcons.Negative:
      return <FaceFrownIcon className='h-6 w-6' />;
    case ScoreIcons.Neutral:
      return <FaceNutralIcon className='h-6 w-6' />;
    case ScoreIcons.Positive:
      return <FaceSmileIcon className='h-6 w-6' />;
    default:
      return <></>;
  }
};

const ToColorClass = (color?: ScoreColors) => {
  switch (color) {
    case ScoreColors.Red:
      return "text-score-red";
    case ScoreColors.Yellow:
      return "text-score-yellow";
    case ScoreColors.Green:
      return "text-score-green";
    case ScoreColors.Olive:
      return "text-score-olive";
    case ScoreColors.Orange:
      return "text-score-orange";
    default:
      return "";
  }
};

const Chart = (p: RootScore) => {
  if (p.history === undefined || p.history.length < 2) {
    return <div className='pb-2'> </div>;
  }

  const data: Serie = {
    id: p.details?.displayName ?? p.id.toString(),
    color: "hsl(47, 70%, 50%)",
    data: p.history.map((score) => ({ x: (score.timestampMs / 60000).toFixed(2), y: score.value })),
  };

  return (
    <div className='mt-1 h-8 w-full bg-gray-50'>
      <ResponsiveLine
        data={[data]}
        margin={{ top: 5, right: 0, bottom: 0, left: 0 }}
        xScale={{ type: "point" }}
        yScale={{
          type: "linear",
          min: p.details?.renderOptions?.min ?? "auto",
          max: p.details?.renderOptions?.max ?? "auto",
          reverse: false,
        }}
        curve='natural'
        axisTop={null}
        axisRight={null}
        axisBottom={null}
        axisLeft={null}
        enableGridX={false}
        enableGridY={false}
        enableCrosshair={false}
        colors={{ scheme: "dark2" }}
        enablePoints={false}
        enableArea={true}
        areaOpacity={0.2}
        useMesh={true}
        legends={[]}
        isInteractive={false}
      />
    </div>
  );
};

export default Score;
