import { Fragment } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../store";

export interface RecapProps {
  title: string;
  text: string;
}

const Recap: React.FC<RecapProps> = (props) => {
  return (
    <div className='grid grid-cols-1 gap-3'>
      <div className='font-medium text-gray-800 '>{props.title}</div>
      <div className='text-gray-500 text-sm font-normal'>{props.text}</div>
    </div>
  );
};

const Recaps: React.FC = () => {
  const recapEvents = useSelector((state: RootState) => state.interaction.recapEvents);

  return <Fragment>
    {recapEvents.map((ev) => {
      return <Recap key={ev.id} title={ev.displayName ?? "unknown"} text={ev.verbatim ?? ""} />;
    })}
  </Fragment>;
};

export default Recaps;
