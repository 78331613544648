import { useSelector } from "react-redux";
import { useLocation, useSearchParams } from "react-router-dom";
import { InteractionStatus } from "./Models/enumTypes";
import { RootState } from "./store";

export interface AppSettings {
  /**
   * Indicates whether to show the navigation bar or not.
   */
  showNavBar: boolean;

  /**
   * Indicates there is no call currently loaded.
   */

  loading: boolean;
  /**
   * Indicates that current call is inprogress.
   * If there is no call, this will be false
   */
  inProgress: boolean;

  /**
   * Indicates whether the we are in agent mode or supervisor mode.
   */
  isAgent: boolean;
}

function AppSettingsFunc(): AppSettings {
  const loading = useSelector((state: RootState) => state.interaction.loading);
  const interactionStatus = useSelector((state: RootState) => state.interaction.details?.status);
  const inProgress = interactionStatus === InteractionStatus.InProgress;
  const isAgent = useLocation().pathname === "/Agent";

  const [searchParams] = useSearchParams();
  const navBarFlag = searchParams.get("navbar");
  const navBar = navBarFlag === "false" ? false : true;

  return {
    showNavBar: navBar,
    loading,
    inProgress,
    isAgent,
  } as AppSettings;
}

export default AppSettingsFunc;
