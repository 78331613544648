import { DocumentMagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { useState, Fragment } from "react";
import RecomendationModal from "./RecommendationModel";

interface RecommendationProps {
  text: string;
  iconClassName?: string;
  className?: string;
}

const RecommendationBtn: React.FC<RecommendationProps> = (props) => {
  const [showModal, setshowModel] = useState(false);
  // Recomendation is always the first button, and there is only ever one.
  // but what makes this diffrent is the model
  return (
    <Fragment>
      {showModal && <RecomendationModal onClose={() => setshowModel(false)} text={props.text} />}
      <button aria-label='Recommendations' type='button' className={props.className ?? 'text-gray-400 hover:text-gray-800 rounded-md'} onClick={() => setshowModel(true)}>
        <DocumentMagnifyingGlassIcon className={props.iconClassName ?? 'w-6 h-6 fill-current'} />
      </button>
    </Fragment>
  );
};

export default RecommendationBtn;